import { POST, GET, CONFIG_PUT, setAxiosToken } from 'API'
import $store from 'STORE'
import eventBus, { eventNames } from 'EVENT_BUS'
const {
  UPDATE_PROFILE,
  UPDATE_MAP_CONFIG,
  UPDATE_SYSTEM_CONFIG,
  CHANGE_LOCALE
} = eventNames
import { setCookie, getCookie, debounce, getModelKey } from 'HELPERS'
import {
  systemSettings,
  mainMapModules,
  defaultPTConfig,
  defaultItsConfig,
  systemSettingsDefault,
  defaultMapModulesConfig,
  serverToFrontModuleRelations,
  pathToServerModuleRelations
} from '@/config/system-settings'
import localforage from 'LF'

export default class UserController {
  constructor() {
    this.saveConfigInLocalStorage = this.saveConfigInLocalStorage.bind(this)
    this.saveMapConfigInLocalStorage =
      this.saveMapConfigInLocalStorage.bind(this)
    this.saveSystemConfigInLocalStorage =
      this.saveSystemConfigInLocalStorage.bind(this)
    this.saveProfileConfig = this.saveProfileConfig.bind(this)
    this.saveProfileConfig = debounce(this.saveProfileConfig, 64)
  }

  async init() {
    await this.loadUser()
    await this.defineAvailableCities(true)

    this.setSystemConfig()
    this.loadMapConfig()
    this.setRegionConfig()
    this.setPTconfig()
    this.setITSconfig()
    this.startListeners()
  }

  startListeners() {
    eventBus.on(UPDATE_PROFILE, this.saveProfileConfig)
    eventBus.on(CHANGE_LOCALE, this.saveSystemConfigInLocalStorage)
    eventBus.on(UPDATE_MAP_CONFIG, this.saveMapConfigInLocalStorage)
    eventBus.on(UPDATE_SYSTEM_CONFIG, this.saveSystemConfigInLocalStorage)
  }

  destroy() {
    eventBus.off(UPDATE_PROFILE, this.saveProfileConfig)
    eventBus.off(CHANGE_LOCALE, this.saveSystemConfigInLocalStorage)
    eventBus.off(UPDATE_MAP_CONFIG, this.saveMapConfigInLocalStorage)
    eventBus.off(UPDATE_SYSTEM_CONFIG, this.saveSystemConfigInLocalStorage)
  }

  async login({ login, password }) {
    const formData = new FormData()

    formData.append('login', login)
    formData.append('password', password)

    const { data } = await POST('mrir/login', formData)

    this.setToken(data)
  }

  setToken({ token }) {
    if (!token) return

    setCookie('ritmToken', token, 1)
    $store.commit('USER_SET', ['token', token])
    setAxiosToken(token)
  }

  async loadUser() {
    try {
      const [{ data: user }, { data: modules }] = await Promise.all([
        GET('mrir/users/current', null, { maxAge: 86400 }),
        GET(`mrir/modules`, null, { maxAge: 86400 })
      ])

      $store.commit('MRIR_SET', ['modules', modules])

      const modulePerms = new Map()

      modules?.forEach(m => {
        if (m) modulePerms.set(m.name, m)
      })

      if (user.profile?.config) delete user.profile.config

      // for (const key in user.profile.config) {
      //   if ('layerStyles' in user.profile.config[key]) {
      //     delete user.profile.config[key].layerStyles
      //   }
      // }

      $store.commit('USER_SET', ['profile', user.profile])
      $store.commit('USER_SET', ['user', user])
      $store.commit('USER_SET', ['permissions.modules', modulePerms])
    } catch (e) {
      throw e
    }
  }

  // -----*-----*-----*-----*-----*-----*-----*----- //

  async loadMapConfig() {
    const { storeRelations } = $store.state

    const config = await localforage.getItem('mapConfig')

    for (const module in storeRelations) {
      if (config) {
        const mapConfig = JSON.parse(config)

        if (mapConfig[module]) {
          const resultConfig = mapConfig[module]
          const hasConfig = Object.keys(resultConfig)?.length

          if (hasConfig) {
            $store.commit(storeRelations[module], ['map', resultConfig])
          }
        }
      } else {
        $store.commit(storeRelations[module], ['map', {}])

        this.saveMapConfigInLocalStorage()
      }
    }
  }

  async setSystemConfig() {
    const config = await localforage.getItem('systemConfig')
    const systemConfig = JSON.parse(config)

    for (const key in systemSettings) {
      $store.commit('SYSTEM_SET', [
        systemSettings[key],
        systemConfig?.[systemSettings[key]] ||
          systemSettingsDefault[systemSettings[key]]
      ])
    }

    if (!systemConfig) {
      this.saveSystemConfigInLocalStorage()
    }
  }

  async defineAvailableCities(withCache) {
    try {
      const { data } = await GET(
        'mrir/regions',
        null,
        !withCache && { maxAge: 0 }
      )

      $store.commit('SYSTEM_SET', ['availableCities', data || []])
    } catch (e) {
      throw e
    }
  }

  async setRegionConfig() {
    const { storeRelations } = $store.state

    const config = await localforage.getItem('regionConfig')
    const regionConfig = JSON.parse(config || '{}')

    if (Object.keys(regionConfig)?.length) {
      for (const module in regionConfig) {
        if (regionConfig[module]) {
          $store.commit(storeRelations[module], ['city', regionConfig[module]])
        }
      }
    }
  }

  async setPTconfig() {
    const ptConfig = await localforage.getItem('ptConfig')

    for (const setting in defaultPTConfig) {
      $store.commit('PUBLIC_TRANSPORT_SET', [
        setting,
        JSON.parse(ptConfig)?.[setting] || defaultPTConfig[setting]
      ])
    }
  }

  async savePTConfig() {
    const ptConfig = {}

    for (const setting in defaultPTConfig) {
      ptConfig[setting] =
        $store.getters.getPTField(setting) || defaultPTConfig[setting]
    }

    await localforage.setItem('ptConfig', JSON.stringify(ptConfig))
  }

  async saveItsControlConfig() {
    const itsConfig = {}

    for (const setting in defaultItsConfig) {
      itsConfig[setting] =
        $store.getters.getItsField(setting) || defaultItsConfig[setting]
    }

    await localforage.setItem('itsControlConfig', JSON.stringify(itsConfig))
  }

  async setITSconfig() {
    const itsControlConfig = await localforage.getItem('itsControlConfig')

    for (const setting in defaultItsConfig) {
      $store.commit('ITS_CONTROL_SET', [
        setting,
        JSON.parse(itsControlConfig)?.[setting] || defaultItsConfig[setting]
      ])
    }
  }

  async prepareConfig({ moduleName, moduleStyles, fullConfig } = {}) {
    let config

    if (fullConfig) {
      config = fullConfig // layer-options saveConfig
    } else {
      const storeModule =
        $store.getters.getModules?.get(moduleName) ||
        $store.getters.getModules?.get(pathToServerModuleRelations[moduleName])

      const { data } = await GET(`mrir/profiles/${storeModule?.id}/current`)
      config = data?.config
    }

    const moduleConfig = {}

    for (const field in defaultMapModulesConfig) {
      moduleConfig[field] = $store.getters.getModuleField({
        module: serverToFrontModuleRelations[moduleName],
        field
      })
    }

    // layer-options saveConfig
    if (moduleName && moduleStyles) {
      const modelKey = getModelKey()

      if (modelKey) {
        moduleConfig.layerStyles = {
          ...(config?.layerStyles || {}),
          [modelKey]: moduleStyles
        }
      } else {
        moduleConfig.layerStyles = moduleStyles
      }
    } else {
      moduleConfig.layerStyles = config?.layerStyles || {}
    }

    return moduleConfig
  }

  prepareRegionConfig() {
    const { storeRelations } = $store.state
    const modules = Object.keys(storeRelations)

    return modules.reduce((a, m) => {
      const moduleRegion = $store.getters.getRegionByModule(m)

      if (moduleRegion) {
        a[m] = moduleRegion
      }

      return a
    }, {})
  }

  prepareMapConfig() {
    const { storeRelations } = $store.state
    const modules = Object.keys(storeRelations)

    return modules.reduce((a, m) => {
      const moduleMap = $store.getters.getMapByModule(m)

      if (moduleMap) {
        a[m] = moduleMap
      }

      return a
    }, {})
  }

  async requestForProfileConfig(data, moduleName) {
    if (!moduleName) return

    const storeModule =
      $store.getters.getModules?.get(moduleName) ||
      $store.getters.getModules?.get(pathToServerModuleRelations[moduleName])
    const moduleId = storeModule?.id
    const { data: profile } = await GET(`mrir/profiles/${moduleId}/current`)

    if (!profile?.id) return

    const url = `mrir/profiles/${moduleId}/${profile.id}`

    return CONFIG_PUT(url, data)
  }

  async saveProfileConfig({ moduleName, moduleStyles, fullConfig } = {}) {
    if (!getCookie('ritmToken')) return

    await this.saveMapConfigInLocalStorage()
    await this.saveSystemConfigInLocalStorage()
    await this.saveItsControlConfig()
    await this.savePTConfig()

    const data = await this.prepareConfig({
      moduleName,
      moduleStyles,
      fullConfig
    })

    return await this.requestForProfileConfig(data, moduleName)
  }

  async saveMapConfigInLocalStorage() {
    const mapConfig = this.prepareMapConfig()
    const regionConfig = this.prepareRegionConfig()

    if (Object.keys(mapConfig).length) {
      await localforage.setItem('mapConfig', JSON.stringify(mapConfig))
    }

    if (Object.keys(regionConfig).length) {
      await localforage.setItem('regionConfig', JSON.stringify(regionConfig))
    }
  }

  async saveSystemConfigInLocalStorage() {
    const { getStateField } = $store.getters
    let config = {}
    for (let key in systemSettings) {
      config[systemSettings[key]] = getStateField(systemSettings[key])
    }

    if (!Object.keys(config).length) return

    await localforage.setItem('systemConfig', JSON.stringify(config))
  }

  async saveConfigInLocalStorage(path) {
    if (document.visibilityState === 'visible') return

    const config = {}
    const name = pathToServerModuleRelations[path]

    if (!mainMapModules.includes(name)) return

    const moduleConfig = {}

    for (const field in defaultMapModulesConfig) {
      moduleConfig[field] = $store.getters.getModuleField({
        module: serverToFrontModuleRelations[name],
        field
      })
    }

    if (!Object.keys(moduleConfig)) return

    config[name] = moduleConfig
    config.system = { timestamp: Date.now() }

    // TODO: temporary off
    // if (e?.type === 'visibilitychange') {
    //   const bc = new BroadcastChannel('localforage')
    //   const oldValue = await localforage.getItem('config')
    //   bc.postMessage({ newValue: config, oldValue })
    // }

    // localStorage.setItem('config', JSON.stringify(config))
  }
}
